<template>
	<v-autocomplete
		v-model="model"
		:label="label"
		:filled="filled"
		:rounded="rounded"
		:flat="flat"
		:solo="solo"
		:solo-inverted="soloInverted"
		:dense="dense"
		:loading="loading"
		:hide-details="hideDetails"
		:items="autocompleteItems"
		:class="className"
		:prepend-inner-icon="prependInnerIcon"
		:disabled="disabled"
		@update:search-input="newVal => onKeyUp({ newVal })"
		:hide-no-data="false"
		return-object
		@change="handleChange"
		:background-color="backgroundColor"
		:outlined="outlined"
		:item-text="itemText"
		:item-value="itemValue"
		:no-data-text="$t('label.no-results-found')"
	></v-autocomplete>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'Autocomplete',
	props: {
		value: {
			type: [String, Number, Object, Array],
			required: false,
		},
		label: {
			type: String,
			required: true,
			default() {
				return '';
			},
		},
		filled: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		rounded: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		flat: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		dense: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		solo: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		soloInverted: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		loading: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		disabled: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		hideDetails: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		className: {
			type: String,
			required: false,
			default() {
				return '';
			},
		},
		onKeyUp: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
		prependInnerIcon: {
			type: String,
			required: false,
		},
		items: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		outlined: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		backgroundColor: {
			type: String,
			required: false,
		},
		resetValueAfterChange: {
			type: Boolean,
			required: false,
			default() {
				return true;
			},
		},
		itemText: {
			type: String,
			required: false,
			default() {
				return 'text';
			},
		},
		itemValue: {
			type: String,
			required: false,
			default() {
				return 'value';
			},
		},
	},
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
		}),
	},
	data() {
		return {
			model: null,
			autocompleteItems: [],
		};
	},
	methods: {
		handleChange(newVal) {
			this.$emit('change', newVal);
			if (this.resetValueAfterChange) {
				setTimeout(
					function () {
						this.model = null;
					}.bind(this),
					500,
				);
			}
		},
	},
	watch: {
		items: {
			handler: function (newVal, oldVal) {
				if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
					this.autocompleteItems = newVal;
				}
			},
			deep: true,
			immediate: false,
		},
		value: {
			handler: function (newVal) {
				if (newVal) {
					this.model = newVal;
				} else {
					this.model = null;
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
