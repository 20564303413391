<template>
	<div
		:class="`candidate-container pane-${paneMode}`"
		:style="`background: ${isDark ? '#424242' : '#eeeeee'};`"
	>
		<div
			class="candidate-header"
			:style="`background: ${isDark ? '#121212' : 'white'};`"
		>
			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<v-btn icon @click="handleClose" v-on="on">
						<v-icon v-if="paneMode === 'nosplit'">arrow_back</v-icon>
						<v-icon v-else>close</v-icon>
					</v-btn>
				</template>
				<span v-if="isSearch">{{ $t('tooltip.back') }}</span>
				<span v-else>{{ $t('tooltip.back-to-candidates') }}</span>
			</v-tooltip>

			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<v-btn icon @click="handleEditCandidateProps" v-on="on"
						><v-icon>edit</v-icon></v-btn
					>
				</template>
				<span>{{ $t('tooltip.edit-candidate') }}</span>
			</v-tooltip>

			<v-tooltip bottom v-if="userRole >= 666">
				<template v-slot:activator="{ on }">
					<v-btn icon @click="handleDeleteCandidateClick" v-on="on"
						><v-icon>delete</v-icon></v-btn
					>
				</template>
				<span>{{ $t('tooltip.delete-candidate') }}</span>
			</v-tooltip>

			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<v-btn
						icon
						@click="() => handlePinCandidateClick(candidate.is_pinned)"
						v-on="on"
						><v-icon :color="candidate.is_pinned ? '#eab73e' : ''">{{
							candidate.is_pinned ? 'star' : 'star_border'
						}}</v-icon></v-btn
					>
				</template>
				<span>{{
					candidate.is_pinned
						? $t('tooltip.starred')
						: $t('tooltip.not-starred')
				}}</span>
			</v-tooltip>

			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<v-btn icon @click="handleRefresh" v-on="on">
						<v-icon>refresh</v-icon>
					</v-btn>
				</template>
				{{ $t('tooltip.refresh') }}
			</v-tooltip>

			<h3 style="margin-left: 0.5rem">
				{{ candidate.name }}
			</h3>

			<v-tooltip v-if="candidate.identifier" bottom>
				<template v-slot:activator="{ on }">
					<div v-on="on">
						<p
							@click="handleCopyJobCodeToClipboard"
							class="candidate-identifier-text fw-light fs-6 mb-0 ml-1"
						>
							{{ `(${candidate.identifier})` }}
						</p>
					</div>
				</template>
				{{ $t('button.copy-candidate-identifier') }}
			</v-tooltip>

			<v-spacer></v-spacer>
			<v-btn
				v-if="
					isKolayIKPartner && isHired() && !candidate.is_synced_with_kolay_ik
				"
				@click="handleOpenCreateKolayIKRecordDialog"
				small
			>
				<v-icon left>sync</v-icon>
				{{ $t('label.kolayik.send-to-kolayik') }}
			</v-btn>
			<v-btn
				v-if="isKolayIKPartner && candidate.is_synced_with_kolay_ik"
				@click="handleOpenEditKolayIKRecordDialog"
				small
			>
				<v-icon left>edit</v-icon>
				{{ $t('label.kolayik.edit-kolayik-record') }}
			</v-btn>
		</div>

		<v-divider></v-divider>

		<div class="candidate-body">
			<v-container fluid style="max-width: 1785px">
				<v-row>
					<v-col cols="12" sm="12" md="8" lg="8" xl="9">
						<v-card>
							<v-card-title class="pa-0">
								<v-toolbar flat>
									<v-tabs
										v-model="tab"
										align-with-title
										slider-size="3"
										slider-color="primary"
									>
										<v-tab>
											{{ $t('tab.overview').toLocaleUpperCase(locale) }}
										</v-tab>
										<v-tab>
											<v-badge
												color="grey darken-2"
												:content="commentsCount.toString()"
												inline
											>
												{{ $t('tab.comments') }}
											</v-badge>
										</v-tab>
										<v-tab>
											<v-badge
												color="grey darken-2"
												:content="
													(candidate && candidate.feedbacks
														? candidate.feedbacks.length
														: 0
													).toString()
												"
												inline
											>
												{{ $t('tab.feedbacks').toLocaleUpperCase(locale) }}
											</v-badge>
										</v-tab>
										<v-tab>
											<v-badge
												color="grey darken-2"
												:content="
													(candidate && candidate.forms_length
														? candidate.forms_length
														: 0
													).toString()
												"
												inline
											>
												{{ $t('tab.forms').toLocaleUpperCase(locale) }}
											</v-badge>
										</v-tab>
										<v-tab v-if="company.is_spica_integration_enabled">
											{{ $t('tab.assessments').toLocaleUpperCase(locale) }}
										</v-tab>
										<v-tab>
											{{ $t('tab.emails').toLocaleUpperCase(locale) }}
										</v-tab>
										<v-tab>
											{{ $t('tab.activities').toLocaleUpperCase(locale) }}
										</v-tab>
									</v-tabs>
									<v-spacer></v-spacer>
								</v-toolbar>
							</v-card-title>

							<v-divider></v-divider>

							<v-card-text style="padding-top: 1rem">
								<v-tabs-items v-model="tab">
									<v-tab-item>
										<Overview
											:candidate="candidate"
											:onArchiveCandidateDialogOpen="
												handleArchiveCandidateDialogOpen
											"
											:onGoToFeedbacks="handleGoToFeedbacks"
										></Overview>
									</v-tab-item>
									<v-tab-item eager>
										<Notes
											:candidate="candidate"
											:onSendNotesCount="handleReceiveNotesCount"
										></Notes>
									</v-tab-item>
									<v-tab-item>
										<Feedbacks :candidate="candidate"></Feedbacks>
									</v-tab-item>
									<v-tab-item>
										<Forms :candidate="candidate"></Forms>
									</v-tab-item>
									<v-tab-item v-if="company.is_spica_integration_enabled">
										<Assessments :candidate="candidate" />
									</v-tab-item>
									<v-tab-item>
										<Emails :candidate="candidate"></Emails>
									</v-tab-item>
									<v-tab-item>
										<Activities :candidate="candidate"></Activities>
									</v-tab-item>
								</v-tabs-items>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col cols="12" sm="12" md="4" lg="4" xl="3">
						<v-card>
							<v-list>
								<v-subheader>
									{{ $t('label.linkedin-profile') }}
									<v-spacer></v-spacer>
									<v-btn icon small @click="handleEditCandidateProps">
										<v-icon>edit</v-icon>
									</v-btn>
								</v-subheader>
								<v-list-item
									v-if="
										candidate.linkedin_profile &&
										candidate.linkedin_profile.length > 0
									"
									@click="
										handleClickLink({
											url: candidate.linkedin_profile,
										})
									"
								>
									<v-list-item-icon>
										<v-icon>mdi-linkedin</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>{{
											candidate.linkedin_profile
										}}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-list-item v-else disabled>
									<v-list-item-icon>
										<v-icon>mdi-linkedin</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>-</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</v-list>

							<v-divider></v-divider>

							<v-list>
								<v-subheader>
									{{ $t('subheader.files') }}
									<v-spacer></v-spacer>
									<v-btn icon small @click="handleAddCandidateFileOpen">
										<v-icon>add_circle</v-icon>
									</v-btn>
								</v-subheader>
								<v-list-item
									v-for="(item, index) in candidate.files"
									:key="index"
									@click="() => handleViewFile({ fileId: item.id })"
								>
									<v-list-item-icon>
										<v-icon v-if="item.content_type === 'application/pdf'"
											>picture_as_pdf</v-icon
										>
										<v-icon v-else>insert_drive_file</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>{{
											item.original_file_name
										}}</v-list-item-title>
										<v-list-item-subtitle>{{
											`${formatDate(item.created)}`
										}}</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action>
										<v-menu bottom left>
											<template v-slot:activator="{ on }">
												<v-btn icon small v-on="on">
													<v-icon>more_vert</v-icon>
												</v-btn>
											</template>
											<v-list>
												<v-list-item
													@click="
														() =>
															handleParseAndAutofill({
																fileIndex: index,
																fileId: item.id,
															})
													"
												>
													<v-list-item-icon>
														<v-icon>auto_fix_high</v-icon>
													</v-list-item-icon>
													<v-list-item-title>{{
														$t('text.parse-and-autofill')
													}}</v-list-item-title>
												</v-list-item>
												<v-list-item
													@click="
														() =>
															handleDeleteFileConfirm({
																fileIndex: index,
																fileName: item.original_file_name,
															})
													"
												>
													<v-list-item-icon>
														<v-icon>delete</v-icon>
													</v-list-item-icon>
													<v-list-item-title>{{
														$t('text.delete-file')
													}}</v-list-item-title>
												</v-list-item>
											</v-list>
										</v-menu>
									</v-list-item-action>
								</v-list-item>
								<v-list-item disabled v-if="candidate.files.length === 0">
									<v-list-item-icon>
										<v-icon>not_interested</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										{{ $t('text.no-files-found') }}
									</v-list-item-content>
								</v-list-item>
							</v-list>

							<v-divider></v-divider>

							<v-list>
								<v-subheader>
									{{ $t('subheader.e-mail') }}
									<v-spacer></v-spacer>
									<v-btn icon small @click="handleEditCandidateProps">
										<v-icon>add_circle</v-icon>
									</v-btn>
								</v-subheader>
								<v-list-item
									v-for="(item, i) in candidate.email"
									:key="i"
									@click="
										() =>
											handlecopyTextToClipboard({
												text: item,
											})
									"
								>
									<v-list-item-icon>
										<v-icon>email</v-icon>
									</v-list-item-icon>
									<v-list-item-content style="cursor: copy">
										<v-list-item-title>{{ item }}</v-list-item-title>
									</v-list-item-content>
									<v-list-item-action>
										<v-menu bottom left>
											<template v-slot:activator="{ on }">
												<v-btn icon small v-on="on">
													<v-icon>more_vert</v-icon>
												</v-btn>
											</template>
											<v-list>
												<v-list-item @click="handleEditCandidateProps">
													<v-list-item-icon>
														<v-icon>edit</v-icon>
													</v-list-item-icon>
													<v-list-item-title>{{
														$t('text.edit')
													}}</v-list-item-title>
												</v-list-item>
											</v-list>
										</v-menu>
									</v-list-item-action>
								</v-list-item>
							</v-list>

							<v-divider></v-divider>

							<v-list>
								<v-subheader>
									{{ $t('subheader.phone') }}
									<v-spacer></v-spacer>
									<v-btn icon small @click="handleEditCandidateProps">
										<v-icon>add_circle</v-icon>
									</v-btn>
								</v-subheader>
								<v-list-item
									v-for="(item, i) in candidate.phone"
									:key="i"
									@click="
										() =>
											handlecopyTextToClipboard({
												text: item,
											})
									"
								>
									<v-list-item-icon>
										<v-icon>phone</v-icon>
									</v-list-item-icon>
									<v-list-item-content style="cursor: copy">
										<v-list-item-title>{{ item }}</v-list-item-title>
									</v-list-item-content>
									<v-list-item-action>
										<v-menu bottom left>
											<template v-slot:activator="{ on }">
												<v-btn icon small v-on="on">
													<v-icon>more_vert</v-icon>
												</v-btn>
											</template>
											<v-list>
												<v-list-item @click="handleEditCandidateProps">
													<v-list-item-icon>
														<v-icon>edit</v-icon>
													</v-list-item-icon>
													<v-list-item-title>{{
														$t('text.edit')
													}}</v-list-item-title>
												</v-list-item>
											</v-list>
										</v-menu>
									</v-list-item-action>
								</v-list-item>
							</v-list>

							<!-- <v-divider></v-divider>

              <v-list>
                <v-subheader>
                  {{ $t('subheader.link-questions') }}
                  <v-spacer></v-spacer>
                  <v-btn icon small @click="handleEditCandidateProps">
                    <v-icon>add_circle</v-icon>
                  </v-btn>
                </v-subheader>
                <v-list-item
                  v-for="(item, i) in candidate.link_questions"
                  :key="i"
                  two-line
                  @click="() => handleLinkQuestionAnswerClick({ answer: item.value })"
                  target="_blank"
                >
                  <v-list-item-icon>
                    <v-icon>open_in_new</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{ item.label }}</v-list-item-subtitle>
                    <v-list-item-title>{{ item.value }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item disabled v-if="candidate.link_questions.length === 0">
                  <v-list-item-icon>
                    <v-icon>not_interested</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{ $t('text.no-link-questions-found') }}
                  </v-list-item-content>
                </v-list-item>
              </v-list> -->

							<v-divider></v-divider>

							<Approvals :candidate="candidate"></Approvals>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
			<!-- do not remove line above -->
			<div id="copyArea"></div>
		</div>

		<v-snackbar
			v-model="snackbar.isVisible"
			:timeout="snackbar.timeout"
			left
			bottom
		>
			{{ snackbar.text }}
			<template v-slot:action="{ attrs }">
				<v-btn
					dark
					text
					icon
					v-bind="attrs"
					@click="snackbar.isVisible = false"
					style="margin-right: 0.5rem"
				>
					<v-icon>close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
		<ViewFile
			v-if="isViewFileVisible"
			:fileId="fileId"
			:onClose="handleViewFileClose"
		></ViewFile>
		<AddCandidateFile
			v-if="isAddCandidateFileVisible"
			:onSave="handleAddCandidateFileSave"
			:onClose="handleAddCandidateFileClose"
		></AddCandidateFile>
		<EditCandidateProps
			v-if="isEditCandidatePropsVisible"
			:onClose="handleEditCandidatePropsClose"
			:candidate="candidate"
			:parseAndAutofillFileId="parseAndAutofillFileId"
		></EditCandidateProps>
		<ArchiveCandidateDialog
			v-if="isArchiveCandidateDialogVisible"
			:candidate="candidate"
			:job-id-initial="jobIdToArchive"
			:application-id-initial="applicationIdToArchive"
			:archive-reason-initial="archiveReasonToArchive"
			:prev-status-initial="prevStatusToArchive"
			:onClose="handleArchiveCandidateDialogClose"
		></ArchiveCandidateDialog>
		<Confirm
			v-if="isConfirmVisible"
			:onConfirm="handleConfirmDialogConfirm"
			:onClose="handleConfirmDialogClose"
			:primaryActionColor="confirmBoxPrimaryActionColor"
		>
			<div style="padding-top: 20px" v-html="confirmText"></div>
		</Confirm>
		<EditKolayIKRecordDialog
			v-if="
				isCreateKolayIKRecordDialogVisible || isEditKolayIKRecordDialogVisible
			"
			:candidate="candidate"
			:on-close="handleCloseEditKolayIKRecordDialog"
			:method="isCreateKolayIKRecordDialogVisible ? 'post' : 'put'"
		></EditKolayIKRecordDialog>
		<EditFormDialog
			v-if="isEditFormDialogVisible"
			:formId="formIdToEdit"
			:candidate="candidate"
			:onClose="handleEditFormDialogClose"
		></EditFormDialog>
		<ViewLinkQuestionAnswer
			v-if="isViewLinkQuestionAnswerVisible"
			:answer="linkQuestionAnswerToDisplay"
			:onClose="handleViewLinkQuestionAnswerClose"
		></ViewLinkQuestionAnswer>
		<router-view></router-view>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import ViewFile from '@/components/shared/viewFile/ViewFile';
import Confirm from '@/components/shared/dialogs/confirm/Confirm';
import Overview from './overview/Overview';
import Notes from './notes/Notes';
import Assessments from './assessments/Assessments.vue';
import Emails from './emails/Emails';
import Feedbacks from './feedbacks/Feedbacks';
import Forms from './forms/Forms';
import Activities from './activities/Activities';
import AddCandidateFile from './addCandidateFile/AddCandidateFile';
import EditCandidateProps from './editCandidateProps/EditCandidateProps';
import ArchiveCandidateDialog from '../archive/archiveCandidateDialog/ArchiveCandidateDialog';
import EditKolayIKRecordDialog from './kolayik/EditKolayIKRecordDialog';
import Approvals from './approvals/Approvals';
import EditFormDialog from './forms/editFormDialog/EditFormDialog';
import ViewLinkQuestionAnswer from './viewLinkQuestionAnswer/ViewLinkQuestionAnswer';
import api from '@/api';
import { format } from 'date-fns';

export default {
	name: 'Candidate',
	components: {
		Assessments,
		ViewFile,
		Confirm,
		Overview,
		Notes,
		Emails,
		Feedbacks,
		Forms,
		Activities,
		AddCandidateFile,
		EditCandidateProps,
		ArchiveCandidateDialog,
		Approvals,
		EditKolayIKRecordDialog,
		EditFormDialog,
		ViewLinkQuestionAnswer,
	},
	computed: {
		...mapState({
			currentLang: state => state.currentUser.lang,
			itemNeedsToRefresh: state => state.candidates.itemNeedsToRefresh,
			isDark: state => state.currentUser.isDark,
			userRole: state => state.currentUser.userRole,
			isKolayIKPartner: state => state.company.company.is_kolay_partner,
			paneMode: state => state.currentUser.paneMode,
			company: state => state.company.company,
		}),
		locale() {
			return this.currentLang === 'tr' ? 'tr-TR' : 'en-US';
		}
	},
	data() {
		const { is_search } = this.$route.query;
		return {
			isVisible: false,
			tab: 0,
			candidate: {
				files: [],
				applications: [],
				email: [],
				phone: [],
				link_questions: [],
			},
			fileId: null,
			isCreateKolayIKRecordDialogVisible: false,
			isEditKolayIKRecordDialogVisible: false,
			isViewFileVisible: false,
			isAddCandidateFileVisible: false,
			isConfirmVisible: false,
			confirmText: '',
			confirmAction: null,
			confirmParams: {},
			confirmBoxPrimaryActionColor: 'primary',
			isEditCandidatePropsVisible: false,
			isArchiveCandidateDialogVisible: false,
			jobIdToArchive: null,
			applicationIdToArchive: null,
			prevStatusToArchive: null,
			archiveReasonToArchive: null,
			snackbar: {
				isVisible: false,
				timeout: 3000,
				text: '',
			},
			isSearch: is_search,
			commentsCount: 0,
			isAutofillVisible: false,
			parsedJsonSchema: null,
			parseAndAutofillFileId: null,
			isEditFormDialogVisible: false,
			formIdToEdit: null,
			isViewLinkQuestionAnswerVisible: false,
			linkQuestionAnswerToDisplay: null,
		};
	},
	methods: {
		handleCopyJobCodeToClipboard() {
			const {
				candidate: { identifier },
			} = this;

			navigator.clipboard.writeText(identifier).then(
				() => {
					this.snackbar = {
						isVisible: true,
						timeout: 3000,
						text: 'Copied!',
					};
				},
				err => {
					console.error('Could not copy text: ', err);
				},
			);
		},
		formatDate(date) {
			return format(new Date(date), 'dd/MM/yyyy HH:mm');
		},
		isHired() {
			const { query } = this.$route;
			const { is_archived, archive_status } = query;
			return is_archived && archive_status === 'archive-hired';
		},
		handleClose() {
			const { currentLang } = this;
			const { query } = this.$route;
			const { is_search } = query;
			if (is_search) {
				window.history.back();
			} else {
				this.$router.push({
					path: `/${currentLang}/candidates`,
					query,
				});
			}
		},
		getCandidate({ id, initializeTabs = true }) {
			this.$store
				.dispatch('candidates/get', { id, refresh: true })
				.then(response => {
					const { items } = response.data;
					if (items.length > 0) {
						this.candidate = { ...items[0] };
						if (initializeTabs) {
							this.handleInitiailizeTab();
						}
					}
				})
				.catch(error => {
					if (error.status === 403) {
						this.handleClose();
					}
				});
		},
		handleViewFile({ fileId }) {
			this.fileId = fileId;
			this.isViewFileVisible = true;
		},
		handleViewFileClose() {
			this.fileId = null;
			this.isViewFileVisible = false;
		},
		handleAddCandidateFileClose() {
			this.isAddCandidateFileVisible = false;
		},
		handleAddCandidateFileOpen() {
			this.isAddCandidateFileVisible = true;
		},
		handleAddCandidateFileSave({ addedFiles = [] }) {
			if (addedFiles.length > 0) {
				const { id, files } = this.candidate;
				this.$store
					.dispatch('candidates/put', {
						items: [
							{
								id,
								files: [...files.map(({ id }) => id), ...addedFiles],
							},
						],
					})
					.then(() => {
						this.handleAddCandidateFileClose();
						this.getCandidate({ id });
					});
			}
		},
		handleConfirmDialogConfirm() {
			const { confirmAction, confirmParams } = this;
			this[confirmAction](confirmParams);
		},
		handleConfirmDialogClose() {
			this.isConfirmVisible = false;
		},
		handleDeleteFileConfirm({ fileIndex, fileName }) {
			this.confirmText = this.$t('text.delete-file-confirmation', {
				fileName,
			});
			this.confirmAction = 'handleDeleteFile';
			this.confirmParams = { fileIndex };
			this.confirmBoxPrimaryActionColor = 'error';
			this.isConfirmVisible = true;
		},
		handleDeleteFile({ fileIndex }) {
			const { id, files } = this.candidate;
			this.$store
				.dispatch('candidates/put', {
					items: [
						{
							id,
							files: files
								.filter((item, index) => index !== fileIndex)
								.map(({ id }) => id),
						},
					],
				})
				.then(() => {
					this.handleConfirmDialogClose();
					this.getCandidate({ id });
				});
		},
		handleOpenScheduleDialog() {
			const { currentLang } = this;
			const { query } = this.$route;
			const { id } = this.candidate;
			this.$router.push({
				path: `/${currentLang}/candidates/edit/${id}/schedule`,
				query,
			});
		},
		handleOpenArchiveDialog() {
			const { currentLang } = this;
			const { query } = this.$route;
			const { id } = this.candidate;
			this.$router.push({
				path: `/${currentLang}/candidates/edit/${id}/archive`,
				query,
			});
		},
		handleEditCandidatePropsClose({ needsRefresh = false }) {
			this.isEditCandidatePropsVisible = false;
			if (needsRefresh) {
				const { id } = this.candidate;
				this.getCandidate({ id });
			}
		},
		handleEditCandidateProps() {
			(this.parseAndAutofillFileId = null),
				(this.isEditCandidatePropsVisible = true);
		},

		handlecopyTextToClipboard({ text }) {
			var textArea = document.createElement('textarea');
			textArea.style.position = 'fixed';
			textArea.style.top = 0;
			textArea.style.left = 0;
			textArea.style.width = '2em';
			textArea.style.height = '2em';
			textArea.style.padding = 0;
			textArea.style.border = 'none';
			textArea.style.outline = 'none';
			textArea.style.boxShadow = 'none';
			textArea.style.background = 'transparent';
			textArea.value = text;
			document.getElementById('copyArea').appendChild(textArea);
			textArea.focus();
			textArea.select();
			try {
				var success = document.execCommand('copy');
				if (success) {
					this.snackbar = {
						isVisible: true,
						timeout: 3000,
						text: 'Copied!',
					};
				}
			} catch (err) {
				console.log('Oops, unable to copy');
			}
			document.getElementById('copyArea').removeChild(textArea);
		},
		handleArchiveCandidateDialogClose({
			checkRuleset,
			prevStatus,
			nextStatus,
			applicationId,
			jobId,
		}) {
			this.isArchiveCandidateDialogVisible = false;

			if (checkRuleset) {
				this.handleCheckStatusChangeRuleset({
					prevStatus,
					nextStatus,
					candidateId: this.candidate.id,
					applicationId,
					jobId,
				});
			}
		},
		handleArchiveCandidateDialogOpen({
			job_id,
			application_id,
			prev_status,
			archive_reason,
		}) {
			this.jobIdToArchive = job_id;
			this.applicationIdToArchive = application_id;
			this.archiveReasonToArchive = archive_reason;
			this.prevStatusToArchive = prev_status;
			this.isArchiveCandidateDialogVisible = true;
		},
		handleGoToFeedbacks({ tab = 2 }) {
			this.tab = tab;
		},
		handleRefresh() {
			const { id } = this.candidate;
			this.getCandidate({ id });
		},
		handleInitiailizeTab() {
			const { tab } = this.$route.query;
			if (tab) {
				if (tab === 'comments') {
					this.tab = 1;
				} else if (tab === 'feedbacks') {
					this.tab = 2;
				} else if (tab === 'forms') {
					this.tab = 3;
				} else if (tab === 'emails') {
					this.tab = 4;
				} else if (tab === 'activities') {
					this.tab = 5;
				} else {
					this.tab = 0;
				}
			} else {
				this.tab = 0;
			}
		},
		handleReceiveNotesCount({ count }) {
			this.commentsCount = count;
		},
		handlePinCandidateClick(isPinned) {
			if (isPinned) {
				this.$store
					.dispatch('candidates/unpinCandidate', {
						candidateId: this.candidate.id,
					})
					.then(() => {
						this.candidate.is_pinned = false;
					});
			} else {
				this.$store
					.dispatch('candidates/pinCandidate', {
						candidateId: this.candidate.id,
					})
					.then(() => {
						this.candidate.is_pinned = true;
					});
			}
		},
		handleDeleteCandidateClick() {
			this.confirmText = this.$t('text.delete-candidate-confirmation', {
				candidateName: this.candidate.name,
			});
			this.confirmAction = 'handleDeleteCandidate';
			this.confirmBoxPrimaryActionColor = 'error';
			this.isConfirmVisible = true;
		},
		async handleDeleteCandidate() {
			await api.candidates.delete({ id: this.candidate.id });
			this.$store.commit('candidates/setItemsNeedToRefresh');
			this.isConfirmVisible = false;
			this.handleClose();
		},
		handleParseAndAutofill({ fileId }) {
			this.parseAndAutofillFileId = fileId;
			this.isEditCandidatePropsVisible = true;
		},
		handleOpenCreateKolayIKRecordDialog() {
			this.isCreateKolayIKRecordDialogVisible = true;
		},
		handleOpenEditKolayIKRecordDialog() {
			this.isEditKolayIKRecordDialogVisible = true;
		},
		handleCloseEditKolayIKRecordDialog({ refresh = false }) {
			this.isCreateKolayIKRecordDialogVisible = false;
			this.isEditKolayIKRecordDialogVisible = false;

			if (refresh) {
				this.handleRefresh();
			}
		},
		handleClickLink({ url }) {
			if (url) {
				if (
					url.toLowerCase().startsWith('http://') ||
					url.toLowerCase().startsWith('https://')
				) {
					window.open(url.toLowerCase(), '_blank');
				} else {
					window.open(`https://${url.toLowerCase()}`, '_blank');
				}
			}
		},
		async handleCheckStatusChangeRuleset({
			prevStatus,
			nextStatus,
			candidateId,
			applicationId,
			jobId,
		}) {
			const formRulesetsQuery = await this.$store.dispatch('formRulesets/get', {
				updateStore: true,
				refresh: true,
			});
			const { items } = formRulesetsQuery.data;
			const rulesetItem = items.find(
				item =>
					(item.prev_status === prevStatus || item.prev_status === '*') &&
					(item.next_status === nextStatus || item.next_status === '*'),
			);
			if (!rulesetItem) {
				return false;
			}
			const { form_id } = rulesetItem;
			this.handleAddNewForm({
				candidateId,
				formTemplateId: form_id,
				applicationId,
				jobId,
				pipelineStatus: nextStatus,
			});
		},
		async handleAddNewForm({
			candidateId,
			formTemplateId,
			applicationId,
			pipelineStatus,
			jobId,
		}) {
			const formTemplateResponse = await this.$store.dispatch(
				'formTemplates/get',
				{ id: formTemplateId, refresh: true },
			);
			const { created_json_schema, predefined_type } =
				formTemplateResponse.data.items[0];
			const draftFormResponse = await this.$store.dispatch('forms/post', {
				items: [
					{
						json_schema: created_json_schema,
						context_schema: {},
						candidate_id: candidateId,
						application_id: applicationId,
						pipeline_status: pipelineStatus,
						pipeline_status_translation: this.$t(
							`pipeline.steps.${pipelineStatus}`,
						),
						job_id: jobId,
						status: 'draft',
						form_template_predefined_type: predefined_type,
					},
				],
			});
			this.$store.dispatch('drafts/get_forms', {});
			const { id } = draftFormResponse.data.items[0];
			this.handleEditForm({ id });
		},
		handleEditForm({ id }) {
			this.formIdToEdit = id;

			setTimeout(
				function () {
					this.isEditFormDialogVisible = true;
				}.bind(this),
				250,
			);
		},
		handleEditFormDialogClose() {
			this.isEditFormDialogVisible = false;
		},
		handleLinkQuestionAnswerClick({ answer }) {
			this.linkQuestionAnswerToDisplay = answer;
			this.isViewLinkQuestionAnswerVisible = true;
		},
		handleViewLinkQuestionAnswerClose() {
			this.isViewLinkQuestionAnswerVisible = false;
			this.linkQuestionAnswerToDisplay = null;
		},
	},
	created() {
		this.$store.dispatch('tags/get', { updateStore: true, refresh: true });
	},
	mounted() {
		setTimeout(
			function () {
				this.isVisible = true;
			}.bind(this),
			50,
		);
	},
	watch: {
		'$route.params.candidateId': {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal) {
					this.getCandidate({ id: newVal });
				}
			},
			deep: true,
			immediate: true,
		},
		'$route.query.is_search': {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal) {
					this.isSearch = Boolean(newVal);
				}
			},
			deep: true,
			immediate: true,
		},
		itemNeedsToRefresh: {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal) {
					const { id } = this.candidate;
					if (id) {
						this.getCandidate({ id, initializeTabs: false });
					}
				}
			},
			deep: true,
			immediate: false,
		},
	},
};
</script>

<style lang="scss">
.candidate-container {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 4;

	.candidate-header {
		padding: 0.5rem;
		display: flex;
		align-items: center;

		h3 {
			font-weight: normal;
			line-height: 36px;
		}

		.candidate-identifier-text {
			font-size: 0.8rem;
			margin-left: 0.5rem;
			color: rgba(0, 0, 0, 0.32);
			cursor: copy;

			&:hover {
				color: rgba(0, 0, 0, 0.76);
			}
		}
	}

	.candidate-body {
		position: absolute;
		top: 53px;
		left: 0;
		right: 0;
		bottom: 0;
		overflow: auto;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	&.pane-right {
		left: 250px;
		top: 53px;
		border-left: 1px solid rgba(0, 0, 0, 0.12);
	}
}
</style>
