<template>
	<div>
		<v-dialog
			scrollable
			transition="dialog-bottom-transition"
			:value="isVisible"
			max-width="800"
			persistent
			@click:outside="handleClickOutside"
			@keydown.esc="handleClickOutside"
		>
			<v-card>
				<v-card-title style="display: block; padding: 0; font-size: 1rem">
					<v-stepper alt-labels v-model="stepper" flat>
						<v-stepper-header>
							<v-stepper-step :step="1">
								{{ $t('label.requisition-form') }}
							</v-stepper-step>

							<v-divider></v-divider>

							<v-stepper-step :step="2">
								{{ $t('label.approval-flow') }}
							</v-stepper-step>

							<v-divider></v-divider>

							<v-stepper-step :step="3">
								{{ $t('label.summary') }}
							</v-stepper-step>
						</v-stepper-header>
					</v-stepper>
				</v-card-title>

				<v-divider></v-divider>

				<v-card-text style="padding: 0">
					<v-stepper v-model="stepper" flat>
						<v-stepper-items>
							<v-stepper-content :step="1">
								<ValidationObserver
									ref="form_step_1"
									tag="form"
									@submit="handleClickNext"
									id="form_step_1"
								>
									<div v-if="requisitionType === 'new_job'">
										<PositionPicker
											:departmentId="job.department_id"
											:teamId="job.team_id"
											:positionId="
												requisitionFormTemplate.predefined_position_id
											"
											:positionTitle="job.name"
											@change="handlePositionPickerChange"
										></PositionPicker>

										<v-container>
											<v-row v-if="similarPositionRequisitions.length > 0">
												<v-col>
													<fieldset
														class="talentics-fieldset talentics-fieldset-paddings"
													>
														<legend>
															{{
																$t(
																	'label.autofill-from-similar-position-requisitions',
																)
															}}
														</legend>
														<v-radio-group
															v-model="similarPositionRequisitionsRadioGroup"
															dense
															hide-details
															style="margin-top: 0; padding-top: 0"
															@change="
																handleSimilarPositionRequisitionsRadioGroupChange
															"
														>
															<v-radio :value="0">
																<template v-slot:label>
																	<div>
																		{{ $t('text.none') }}
																	</div>
																</template>
															</v-radio>
															<v-radio
																v-for="(
																	similarPositionRequisition, index
																) in similarPositionRequisitions"
																:key="`similarPositionRequisition_${index}`"
																:value="similarPositionRequisition.id"
															>
																<template v-slot:label>
																	<div>
																		{{ job.name }}
																		-
																		{{
																			handleGetUserName({
																				userId:
																					similarPositionRequisition.requested_by,
																			})
																		}}
																		-
																		{{
																			formatDate({
																				timestamp:
																					similarPositionRequisition.updated,
																			})
																		}}
																	</div>
																</template>
															</v-radio>
														</v-radio-group>
													</fieldset>
												</v-col>
											</v-row>

											<!-- USER PICKER -->
											<v-row>
												<v-col>
													<UserPicker
														v-model="job.owner"
														:users="users.filter(item => item.active)"
														:label="$t('label.owner') + '*'"
														dense
														rules="required"
													></UserPicker>
												</v-col>
											</v-row>

											<v-row>
												<v-col>
													<UserPicker
														v-model="job.hiring_manager"
														:users="users.filter(item => item.active)"
														:label="$t('label.hiring-manager') + '*'"
														rules="required"
														dense
													></UserPicker>
												</v-col>
											</v-row>

											<v-row>
												<v-col>
													<ValidationProvider
														rules="required"
														v-slot="{ errors }"
													>
														<fieldset
															class="talentics-fieldset talentics-fieldset-paddings"
														>
															<legend>{{ $t('label.job-type') }}*</legend>
															<v-radio-group
																v-model="requisitionFormTemplate.job_type"
																dense
																hide-details
																style="margin-top: 0; padding-top: 0"
																:error-messages="errors"
															>
																<v-radio value="new_position">
																	<template v-slot:label>
																		<div>
																			{{ $t('label.new-position') }}
																		</div>
																	</template>
																</v-radio>
																<v-radio value="replacement">
																	<template v-slot:label>
																		<div>
																			{{ $t('label.replacement') }}
																		</div>
																	</template>
																</v-radio>
															</v-radio-group>
														</fieldset>
													</ValidationProvider>
												</v-col>
											</v-row>

											<v-row>
												<v-col>
													<ValidationProvider
														rules="required"
														v-slot="{ errors }"
													>
														<v-select
															v-model="requisitionFormTemplate.number_of_people"
															:label="`${$t(
																'label.number-of-people-to-be-hired',
															)}*`"
															:items="[
																{
																	text: 1,
																	value: 1,
																},
																{
																	text: 2,
																	value: 2,
																},
																{
																	text: 3,
																	value: 3,
																},
																{
																	text: 4,
																	value: 4,
																},
																{
																	text: 5,
																	value: 5,
																},
																{
																	text: 6,
																	value: 6,
																},
																{
																	text: 7,
																	value: 7,
																},
																{
																	text: 8,
																	value: 8,
																},
																{
																	text: 9,
																	value: 9,
																},
																{
																	text: 10,
																	value: 10,
																},
															]"
															hide-details
															dense
															outlined
															:error-messages="errors"
														></v-select>
													</ValidationProvider>
												</v-col>
											</v-row>

											<v-row>
												<v-col>
													<ValidationProvider
														rules="required"
														v-slot="{ errors }"
													>
														<v-autocomplete
															v-model="job.location"
															:items="
																locations?.map(item => ({
																	value: item.id,
																	text: item.name,
																	disabled: !item.active,
																}))
															"
															item-text="text"
															item-value="value"
															item-disabled="disabled"
															hide-no-data
															outlined
															:label="`${$t('label.location')}*`"
															:error-messages="errors"
															hide-details
															dense
														></v-autocomplete>
													</ValidationProvider>
												</v-col>
											</v-row>

											<v-row>
												<v-col>
													<ValidationProvider
														rules="required"
														v-slot="{ errors }"
													>
														<v-autocomplete
															v-model="job.work_type"
															:items="
																workTypes.map(item => ({
																	value: item.id,
																	text: item.name,
																	disabled: !item.active,
																}))
															"
															item-text="text"
															item-value="value"
															item-disabled="disabled"
															hide-no-data
															outlined
															:label="`${$t('label.work-type')}*`"
															:error-messages="errors"
															hide-details
															dense
														></v-autocomplete>
													</ValidationProvider>
												</v-col>
											</v-row>

											<v-row
												v-if="
													actionType === 'new' &&
													similarPositionRequisitionsRadioGroup === 0
												"
											>
												<v-col>
													<ValidationProvider
														rules="required"
														v-slot="{ errors }"
													>
														<v-select
															v-model="requisitionFormTemplateId"
															:items="
																requisitionFormTemplates.filter(
																	item => item.active,
																)
															"
															item-text="label"
															item-value="id"
															dense
															outlined
															hide-details
															:label="$t('label.requisition-form')"
															@change="
																newVal =>
																	handleGetRequisitionFormTemplate({
																		id: newVal,
																	})
															"
															:error-messages="errors"
														></v-select>
													</ValidationProvider>
												</v-col>
											</v-row>

											<v-row v-if="requisitionFormTemplate.initialized">
												<v-col>
													<DynamicForm
														:jsonSchema="requisitionFormTemplate.json_schema"
														:contextSchema="
															requisitionFormTemplate.context_schema
														"
														:onChange="handleDynamicFormChange"
													></DynamicForm>
												</v-col>
											</v-row>
										</v-container>
									</div>

									<div v-if="requisitionType === 'new_offer'">
										<v-container>
											<v-row v-if="actionType === 'new'">
												<v-col>
													<ValidationProvider
														rules="required"
														v-slot="{ errors }"
													>
														<v-select
															v-model="offerTemplateId"
															:items="offerTemplates"
															item-text="label"
															item-value="id"
															dense
															outlined
															hide-details
															:label="$t('label.requisition-form')"
															@change="
																newVal =>
																	handleGetOfferTemplate({
																		id: newVal,
																	})
															"
															:error-messages="errors"
														></v-select>
													</ValidationProvider>
												</v-col>
											</v-row>
										</v-container>

										<div v-if="offerTemplateId">
											<v-container>
												<v-row>
													<v-col>
														<fieldset
															class="talentics-fieldset talentics-fieldset-paddings"
														>
															<legend>
																{{ $t('label.offer-letter') }}
															</legend>
															<RichEditor
																v-model="offerTemplate.offer_template"
																refName="offerTemplate"
															></RichEditor>
														</fieldset>
													</v-col>
												</v-row>

												<v-row>
													<v-col>
														<fieldset
															class="talentics-fieldset talentics-fieldset-paddings"
														>
															<legend>
																{{ $t('label.email-settings') }}
															</legend>
															<v-container fluid>
																<v-row>
																	<v-col>
																		<fieldset
																			class="talentics-fieldset talentics-fieldset-paddings"
																		>
																			<legend>
																				{{ $t('label.auto-email') }}
																			</legend>
																			<v-switch
																				v-model="
																					offerTemplate.send_automated_email
																				"
																				color="success"
																				:label="
																					$t(
																						'label.send-auto-email-to-candidate',
																					)
																				"
																				inset
																				hide-details
																				dense
																				class="v-input--template-switch"
																			></v-switch>
																		</fieldset>
																	</v-col>
																</v-row>
															</v-container>
															<div v-if="offerTemplate.send_automated_email">
																<ScheduleEmailForm
																	:emailForm="{
																		send_time: 'afc',
																		template: offerTemplate.email_body,
																		subject: offerTemplate.email_subject,
																		sent_from: offerTemplate.sent_from,
																		send_to: 'hakan+test2345@talentics.app',
																		cc: offerTemplate.cc,
																		bcc: offerTemplate.bcc,
																	}"
																	:completedEmailForm="{
																		template: offerTemplate.email_body,
																		subject: offerTemplate.email_subject,
																	}"
																	:candidateEmails="[
																		'hakan+test2345@talentics.app',
																	]"
																	:previewMode="false"
																	@change-form="handleChangeScheduleEmailForm"
																	:onNewAttachmentAdded="
																		handleNewAttachmentAdded
																	"
																	:onAttachmentDeleteClick="
																		handleAttachmentDeleteClick
																	"
																	send-on-approval-flow-complete
																></ScheduleEmailForm>
															</div>
														</fieldset>
													</v-col>
												</v-row>
											</v-container>
										</div>
									</div>
								</ValidationObserver>
							</v-stepper-content>

							<v-stepper-content :step="2">
								<ValidationObserver
									ref="form_step_2"
									tag="form"
									@submit="handleClickNext"
									id="form_step_1"
								>
									<v-container v-if="suitableRequisitionApprovalFlowError">
										<v-row>
											<v-col>
												<v-alert type="error">
													{{ $t('err.no-suitable-approval-flow') }}
												</v-alert>
											</v-col>
										</v-row>
									</v-container>
									<v-container v-else>
										<v-row
											v-if="
												requisitionType === 'new_offer' ||
												suitableRequisitionApprovalFlowTemplates.length > 1
											"
										>
											<v-col>
												<ValidationProvider
													rules="required"
													v-slot="{ errors }"
												>
													<v-select
														v-model="requisitionApprovalFlowTemplateId"
														:items="suitableRequisitionApprovalFlowTemplates"
														item-text="label"
														item-value="id"
														dense
														outlined
														hide-details
														:label="$t('label.requisition-approval-flow')"
														@change="
															newVal =>
																handleGetRequisitionApprovalFlowTemplate({
																	id: newVal,
																})
														"
														:error-messages="errors"
													></v-select>
												</ValidationProvider>
											</v-col>
										</v-row>
										<v-row v-if="requisitionApprovalFlowTemplate.initialized">
											<v-col>
												<fieldset
													class="talentics-fieldset talentics-fieldset-paddings"
												>
													<legend>
														{{ $t('label.settings') }}
													</legend>
													<v-checkbox
														v-model="
															requisitionApprovalFlowTemplate.publish_job_on_completion
														"
														:label="$t('label.publish_job_on_completion')"
														dense
														hide-details
													></v-checkbox>
												</fieldset>
											</v-col>
										</v-row>
										<v-row v-if="requisitionApprovalFlowTemplate.initialized">
											<v-col>
												<ApprovalFlow
													v-model="requisitionApprovalFlowTemplate.json_schema"
													:userApprovalRequests="user_approval_requests"
													:onRefreshData="handleRefreshApprovalFlowData"
													:mode="userRole === 777 ? 'edit' : 'preview'"
												></ApprovalFlow>
											</v-col>
										</v-row>
									</v-container>
								</ValidationObserver>
							</v-stepper-content>

							<v-stepper-content :step="3">
								<div id="requisition_summary">
									<div v-if="requisitionType === 'new_job'">
										<v-container>
											<v-row>
												<v-col>
													<div>
														<fieldset
															class="talentics-fieldset talentics-fieldset-paddings"
														>
															<legend>
																{{ $t('label.title') }}
															</legend>
															<div v-html="job.name"></div>
														</fieldset>
													</div>

													<div style="margin-top: 2rem">
														<fieldset
															class="talentics-fieldset talentics-fieldset-paddings"
														>
															<legend>
																{{ $t('label.location') }}
															</legend>
															<div
																v-html="
																	(
																		locations.find(
																			({ id }) => id === job.location,
																		) || {}
																	).name
																"
															></div>
														</fieldset>
													</div>

													<div style="margin-top: 2rem">
														<fieldset
															class="talentics-fieldset talentics-fieldset-paddings"
														>
															<legend>
																{{ $t('label.department-team') }}
															</legend>
															<div
																v-html="
																	(
																		teams.find(
																			({ id }) => id === job.team_id,
																		) || {}
																	).name
																"
															></div>
														</fieldset>
													</div>

													<div style="margin-top: 2rem">
														<fieldset
															class="talentics-fieldset talentics-fieldset-paddings"
														>
															<legend>
																{{ $t('label.owner') }}
															</legend>
															<div
																v-html="
																	users.find(user => user.id === job.owner)
																		?.name
																"
															></div>
														</fieldset>
													</div>

													<div style="margin-top: 2rem">
														<fieldset
															class="talentics-fieldset talentics-fieldset-paddings"
														>
															<legend>
																{{ $t('label.hiring-manager') }}
															</legend>
															<div
																v-html="
																	users.find(
																		user => user.id === job.hiring_manager,
																	)?.name
																"
															></div>
														</fieldset>
													</div>

													<div style="margin-top: 2rem">
														<fieldset
															class="talentics-fieldset talentics-fieldset-paddings"
														>
															<legend>
																{{ $t('label.work-type') }}
															</legend>
															<div
																v-html="
																	(
																		workTypes.find(
																			({ id }) => id === job.work_type,
																		) || {}
																	)?.name
																"
															></div>
														</fieldset>
													</div>
												</v-col>
											</v-row>
										</v-container>

										<v-container>
											<v-row>
												<v-col>
													<DynamicForm
														:jsonSchema="requisitionFormTemplate.json_schema"
														:contextSchema="
															requisitionFormTemplate.context_schema
														"
														read-only
													></DynamicForm>
												</v-col>
											</v-row>
										</v-container>
									</div>

									<div v-else-if="requisitionType === 'new_offer'">
										<v-container>
											<v-row>
												<v-col>
													<fieldset
														class="talentics-fieldset talentics-fieldset-paddings"
													>
														<legend>
															{{ $t('label.offer-letter') }}
														</legend>
														<div v-html="offerTemplate.offer_template"></div>
													</fieldset>
												</v-col>
											</v-row>
										</v-container>
									</div>

									<v-container>
										<v-row>
											<v-col>
												<fieldset
													class="talentics-fieldset talentics-fieldset-paddings"
												>
													<legend>
														{{ $t('label.publish_job_on_completion') }}
													</legend>
													<div
														v-html="
															requisitionApprovalFlowTemplate.publish_job_on_completion ? $t('text.yes') : $t('text.no')
														"
													></div>
												</fieldset>
											</v-col>
										</v-row>
									</v-container>
									<v-container>
										<v-row>
											<v-col>
												<ApprovalFlow
													v-model="requisitionApprovalFlowTemplate.json_schema"
													:userApprovalRequests="user_approval_requests"
													:onRefreshData="handleRefreshApprovalFlowData"
													mode="preview"
												></ApprovalFlow>
											</v-col>
										</v-row>
									</v-container>
								</div>
							</v-stepper-content>
						</v-stepper-items>
					</v-stepper>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-btn @click="handleClickPrint" v-if="stepper === 3">
						<v-icon left>print</v-icon>
						{{ $t('button.print') }}
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn @click="onClose" text>
						{{ $t('button.close') }}
					</v-btn>
					<v-btn
						color="secondary"
						@click="() => handleClickSubmit({ status: 'draft' })"
						v-if="status !== 'published' && (stepper === 2 || stepper === 3)"
					>
						<v-icon left>note_alt</v-icon>
						{{ $t('button.save-as-draft') }}
					</v-btn>
					<v-btn
						@click="handleClickBack"
						v-if="status !== 'published' && stepper > 1"
					>
						{{ $t('button.back') }}
					</v-btn>
					<v-btn
						color="primary"
						@click="handleClickNext"
						v-if="status !== 'published' && (stepper === 1 || stepper === 2)"
						:disabled="stepper === 2 && suitableRequisitionApprovalFlowError"
					>
						{{ $t('button.next') }}
					</v-btn>
					<v-btn
						color="primary"
						@click="() => handleClickSubmit({ status: 'published' })"
						v-if="status !== 'published' && stepper === 3"
					>
						<v-icon left>task</v-icon>
						{{ $t('button.start-approval-flow') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { format } from 'date-fns';
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import DynamicForm from '@/components/shared/dynamicForm/DynamicForm';
import ApprovalFlow from '@/components/shared/approvalFlow/ApprovalFlow';
import RichEditor from '@/components/shared/richEditor/RichEditor';
import ScheduleEmailForm from '@/components/shared/scheduleEmailForm/ScheduleEmailForm';
import PositionPicker from '@/components/shared/positionPicker/PositionPicker';
import api from '@/api';
import UserPicker from '@/components/shared/userPicker/UserPicker';

export default {
	computed: {
		...mapState({
			users: state => state.users.items,
			isDark: state => state.currentUser.isDark,
			requisitionFormTemplates: state =>
				state.requisitionFormTemplates.items.filter(
					({ requisition_type }) => requisition_type === 'new_job',
				),
			requisitionApprovalFlowTemplates: state =>
				state.requisitionApprovalFlowTemplates.items,
			locations: state => state.locations.items,
			workTypes: state => state.workTypes.items,
			currentLang: state => state.currentUser.lang,
			teams: state => state.teams.items,
			offerTemplates: state => state.offerTemplates.items,
			userRole: state => state.currentUser.userRole,
		}),
	},
	components: {
		ValidationObserver,
		ValidationProvider,
		DynamicForm,
		ApprovalFlow,
		RichEditor,
		ScheduleEmailForm,
		PositionPicker,
		UserPicker,
	},
	props: {
		onClose: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
		requisitionType: {
			type: String,
			required: true,
		},
		actionType: {
			type: String,
			required: false,
			default() {
				return 'new';
			},
		},
		requisitionId: {
			type: [Number, String],
			required: false,
			default() {
				return null;
			},
		},
	},
	data() {
		return {
			format,
			isVisible: false,
			stepper: 1,
			requisitionFormTemplateId: null,
			requisitionFormTemplate: {
				json_schema: [],
				context_schema: {},
				initialized: false,
				job_type: 'new_position',
				number_of_people: 1,
				predefined_position_id: null,
				predefined_position_preset_id: null,
			},
			requisitionApprovalFlowTemplateId: null,
			requisitionApprovalFlowTemplate: {
				json_schema: [],
				publish_job_on_completion: null,
				initialized: false,
				requisition_approval_flow_template_id: null,
			},
			job: {
				id: null,
				name: '',
				location: null,
				department_id: null,
				team_id: null,
				work_type: null,
				hiring_manager: null,
				owner: null,
				description: null,
				closing: null,
				internal_notes: null,
				list_on_external: false,
				access_users: [],
			},
			suitableRequisitionApprovalFlowTemplates: [],
			suitableRequisitionApprovalFlowError: false,
			status: null,
			user_approval_requests: [],
			offerTemplateId: null,
			offerTemplate: {
				initialized: false,
				email_subject: null,
				email_body: null,
				offer_template: null,
				send_automated_email: false,
				sent_from: null,
				cc: [],
				bcc: [],
				attachments: [],
			},
			similarPositionRequisitions: [],
			similarPositionRequisitionsRadioGroup: 0,
		};
	},
	methods: {
		handleClickOutside(evt) {
			evt.preventDefault();
		},
		handleGetRequisitionFormTemplate({ id }) {
			this.requisitionFormTemplate = {
				...this.requisitionFormTemplate,
				initialized: true,
			};
			this.$store
				.dispatch('requisitionFormTemplates/get', { id, refresh: true })
				.then(response => {
					const { items } = response.data;
					if (items.length > 0) {
						this.requisitionFormTemplate = {
							...this.requisitionFormTemplate,
							json_schema: [...items[0].created_json_schema],
						};
					}
				});
		},
		handleGetOfferTemplate({ id }) {
			this.$store
				.dispatch('offerTemplates/get', { id, refresh: true })
				.then(response => {
					const { items } = response.data;
					if (items.length > 0) {
						this.offerTemplate = {
							...items[0],
						};
					}
				});
		},
		handleGetRequisitionApprovalFlowTemplate({ id }) {
			this.requisitionApprovalFlowTemplate = {
				...this.requisitionApprovalFlowTemplate,
				initialized: true,
			};
			this.$store
				.dispatch('requisitionApprovalFlowTemplates/get', {
					id,
					refresh: true,
				})
				.then(response => {
					const { items } = response.data;
					if (items.length > 0) {
						this.requisitionApprovalFlowTemplate = {
							...this.requisitionApprovalFlowTemplate,
							json_schema: [...items[0].json_schema],
							publish_job_on_completion: items[0].publish_job_on_completion,
						};
					}
				});
		},
		handleDynamicFormChange({ newVal }) {
			this.requisitionFormTemplate = {
				...this.requisitionFormTemplate,
				context_schema: {
					...this.requisitionFormTemplate.context_schema,
					...newVal,
				},
			};
		},
		async handleClickNext(evt) {
			if (evt) {
				evt.preventDefault();
			}

			if (this.stepper === 1) {
				const isValid = await this.$refs.form_step_1.validate();

				if (isValid) {
					this.stepper += 1;
				}
			} else if (this.stepper === 2) {
				const isValid = await this.$refs.form_step_2.validate();

				if (isValid) {
					this.stepper += 1;
				}
			}
		},
		handleClickBack() {
			this.stepper -= 1;
		},
		handlePositionPickerChange({
			department_id,
			team_id,
			predefined_position_id,
			predefined_position_preset_id,
			name,
			team_id_changed = false,
		}) {
			if (team_id_changed && team_id) {
				this.handlePickingApprovalFlow();
			}
			this.similarPositionRequisitionsRadioGroup = 0;

			if (
				predefined_position_preset_id &&
				predefined_position_preset_id !==
					this.requisitionFormTemplate.predefined_position_preset_id
			) {
				this.handleGetSimilarPositionRequisitions({
					predefined_position_preset_id,
				});
			} else {
				this.similarPositionRequisitions = [];
			}
			this.job = {
				...this.job,
				department_id,
				team_id,
				name,
			};

			this.requisitionFormTemplate = {
				...this.requisitionFormTemplate,
				predefined_position_id,
				predefined_position_preset_id,
			};
		},
		handlePickingApprovalFlow() {
			const { team_id } = this.job;
			const { job_type } = this.requisitionFormTemplate;
			const { requisitionApprovalFlowTemplates, requisitionType } = this;
			if (requisitionType === 'new_job') {
				const suitableRequisitionApprovalFlowTemplates =
					requisitionApprovalFlowTemplates.filter(
						({
							team_ids,
							is_valid_job_new_position,
							is_valid_job_replacement,
						}) => {
							if (job_type === 'new_position') {
								return is_valid_job_new_position && team_ids.includes(team_id);
							} else if (job_type === 'replacement') {
								return is_valid_job_replacement && team_ids.includes(team_id);
							}
							return team_ids.includes(team_id);
						},
					);

				if (suitableRequisitionApprovalFlowTemplates?.length > 0) {
					this.suitableRequisitionApprovalFlowTemplates =
						suitableRequisitionApprovalFlowTemplates;
					this.handleGetRequisitionApprovalFlowTemplate({
						id:
							this.requisitionApprovalFlowTemplateId ||
							suitableRequisitionApprovalFlowTemplates[0].id,
					});
					this.suitableRequisitionApprovalFlowError = false;
				} else {
					this.suitableRequisitionApprovalFlowTemplates = [];
					this.requisitionApprovalFlowTemplateId = null;
					this.suitableRequisitionApprovalFlowError = true;
				}
			} else if (requisitionType === 'new_offer') {
				const suitableRequisitionApprovalFlowTemplates =
					requisitionApprovalFlowTemplates;
				this.suitableRequisitionApprovalFlowTemplates =
					suitableRequisitionApprovalFlowTemplates;
			}
		},
		handleGetSimilarPositionRequisitions({ predefined_position_preset_id }) {
			api.requisitions.similar
				.get({ predefined_position_preset_id })
				.then(response => {
					const { items } = response.data;
					this.similarPositionRequisitions = items;
				});
		},
		handleGetJobAccessUsers() {
			const { owner, hiring_manager, access_users } = this.job;
			const { requisitionApprovalFlowTemplate } = this;

			const potential_access_users = [
				...requisitionApprovalFlowTemplate.json_schema.reduce(
					(acc, { approvers }) => {
						return [
							...acc,
							...(approvers ? approvers.map(({ user_id }) => user_id) : []),
						];
					},
					[...access_users, owner, hiring_manager],
				),
			];

			return [...new Set(potential_access_users)];
		},
		async handleClickSubmit({ status }) {
			const { requisitionType } = this;

			if (requisitionType === 'new_offer') {
				api.offers.post();
				return false;
			}

			let job_id = null;
			let requisition_id = this.requisitionId
				? parseInt(this.requisitionId, 10)
				: null;

			// FIRST CHECK IF JOB IS ALREADY CREATED
			// IF JOB ID IS NOT NULL, THEN UPDATE JOB
			if (this.job.id) {
				const jobItems = [
					{
						...this.job,
						access_users: this.handleGetJobAccessUsers(),
					},
				];

				await this.$store.dispatch('jobs/put', { items: jobItems });
				job_id = this.job.id;
			}
			// ELSE CREATE NEW JOB
			else {
				const jobItems = [
					{
						...this.job,
						status: 'pending',
						access_users: this.handleGetJobAccessUsers(),
					},
				];

				const jobRequest = await this.$store.dispatch('jobs/post', {
					items: jobItems,
				});

				if (jobRequest.data.items.length > 0) {
					job_id = jobRequest.data.items[0].id;
				}
			}

			const { actionType, requisitionApprovalFlowTemplate } = this;

			// CREATE NEW REQUISITION
			if (actionType === 'new') {
				const {
					json_schema,
					context_schema,
					job_type,
					number_of_people,
					predefined_position_id,
					predefined_position_preset_id,
				} = this.requisitionFormTemplate;

				const requisitionRequest = await api.requisitions.post({
					items: [
						{
							requisition_type: 'new_job',
							requisition_form_json_schema: json_schema,
							requisition_form_context_schema: context_schema,
							approval_flow_json_schema:
								requisitionApprovalFlowTemplate.json_schema,
							job_type: job_type,
							number_of_people: number_of_people,
							status,
							job_id,
							active: true,
							predefined_position_id: predefined_position_id,
							predefined_position_preset_id: predefined_position_preset_id,
							publish_job_on_completion:
								this.requisitionApprovalFlowTemplate.publish_job_on_completion,
							requisition_approval_flow_template_id:
								this.requisitionApprovalFlowTemplateId || null,
						},
					],
				});

				if (requisitionRequest.data.items.length > 0) {
					requisition_id = requisitionRequest.data.items[0].id;
				}
			}
			// EDIT EXISTING REQUISITION
			else if (actionType === 'edit') {
				const {
					json_schema,
					context_schema,
					job_type,
					number_of_people,
					predefined_position_id,
					predefined_position_preset_id,
				} = this.requisitionFormTemplate;

				await api.requisitions.put({
					items: [
						{
							id: requisition_id,
							requisition_form_json_schema: json_schema,
							requisition_form_context_schema: context_schema,
							approval_flow_json_schema:
								requisitionApprovalFlowTemplate.json_schema,
							job_type: job_type,
							number_of_people: number_of_people,
							status,
							active: true,
							predefined_position_id,
							predefined_position_preset_id,
							publish_job_on_completion:
								requisitionApprovalFlowTemplate.publish_job_on_completion,
							requisition_approval_flow_template_id:
								this.requisitionApprovalFlowTemplateId,
						},
					],
				});
			}

			if (status === 'published') {
				// IF STATUS IS PUBLISHED, THEN CREATE REQUIRED USER APPROVAL REQUESTS
				// AND UPDATE REQUISITION APPROVAL FLOW JSON SCHEMA
				this.requisitionApprovalFlowTemplate = {
					...this.requisitionApprovalFlowTemplate,
					json_schema: await Promise.all(
						this.requisitionApprovalFlowTemplate.json_schema.map(async step => {
							return {
								approvers: await Promise.all(
									step.approvers.map(async approver => {
										const { user_id } = approver;
										const approvalRequestRequest =
											await api.userApprovalRequests.post({
												items: [
													{
														job_id,
														requested_from: user_id,
														status: 'queued',
														requisition_type: this.requisitionType,
														requisition_id,
													},
												],
											});
										return {
											...approver,
											approval_request_id:
												approvalRequestRequest.data.items[0][0].id,
										};
									}),
								),
							};
						}),
					),
				};

				// UPDATE REQUISITION APPROVAL FLOW JSON SCHEMA
				await api.requisitions.put({
					items: [
						{
							id: requisition_id,
							approval_flow_json_schema:
								this.requisitionApprovalFlowTemplate.json_schema,
						},
					],
					set_approval_request_statuses: true,
					requisition_id,
				});
			}

			// FINALLY REDIRECT USER TO RELATED JOB PAGE
			this.$store.commit('jobs/setItemNeedsToRefresh');
			const { currentLang } = this;
			this.$router.push({
				path: `/${currentLang}/jobs/edit/${job_id}`,
				query: {
					status: 'pending',
				},
			});
		},
		handleGetRequisition({ id, fillFromSimilarRequisition = false }) {
			api.requisitions.get({ id }).then(response => {
				const { items } = response.data;

				if (items.length > 0) {
					const {
						requisition_form_json_schema,
						requisition_form_context_schema,
						approval_flow_json_schema,
						status,
						job_id,
						user_approval_requests,
						number_of_people,
						job_type,
						predefined_position_id,
						predefined_position_preset_id,
						publish_job_on_completion,
						requisition_approval_flow_template_id,
					} = items[0];

					if (fillFromSimilarRequisition === false) {
						this.status = status;
						this.user_approval_requests = user_approval_requests;

						this.requisitionFormTemplate = {
							...this.requisitionFormTemplate,
							json_schema: [...requisition_form_json_schema],
							context_schema: {
								...requisition_form_context_schema,
							},
							number_of_people,
							job_type,
							predefined_position_id,
							predefined_position_preset_id,
							initialized: true,
						};

						this.requisitionApprovalFlowTemplate = {
							json_schema: [...approval_flow_json_schema],
							publish_job_on_completion,
							requisition_approval_flow_template_id,
							initialized: true,
						};

						this.handleGetJob({ id: job_id });

						if (status === 'published') {
							this.stepper = 3;
						}
					} else {
						this.requisitionFormTemplate = {
							...this.requisitionFormTemplate,
							json_schema: [...requisition_form_json_schema],
							context_schema: {
								...requisition_form_context_schema,
							},
							number_of_people,
							job_type,
							predefined_position_id,
							predefined_position_preset_id,
							initialized: true,
						};

						this.requisitionApprovalFlowTemplate = {
							json_schema: [...approval_flow_json_schema],
							publish_job_on_completion,
							requisition_approval_flow_template_id,
							initialized: true,
						};

						this.handleGetJob({ id: job_id });
					}
				}
			});
		},
		handleGetJob({ id }) {
			api.jobs.get({ id }).then(response => {
				const { items } = response.data;

				if (items.length > 0) {
					this.job = {
						...items[0],
					};
				}
			});
		},
		handleRefreshApprovalFlowData() {
			const { requisitionId, requisitionType } = this;
			this.handleGetRequisition({ id: requisitionId });

			if (requisitionType === 'new_job') {
				this.$store.commit('jobs/setItemNeedsToRefresh');
			}
		},
		handleClickPrint() {
			var mywindow = window.open('', 'PRINT', 'height=400,width=600');

			mywindow.document.write('<html><head><title>PRINT</title>');
			mywindow.document.write(
				'<link rel="stylesheet" href="//fonts.googleapis.com/css?family=Roboto:400,500,700,400italic|Material+Icons">',
			);
			mywindow.document.write(
				'<link href="https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css" rel="stylesheet">',
			);
			mywindow.document.write(
				'<link href="https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css" rel="stylesheet">',
			);
			mywindow.document.write(
				'<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, minimal-ui">',
			);
			mywindow.document.write(
				"<style>* { font-family: 'Roboto', sans-serif; } fieldset.talentics-fieldset-paddings { padding: 1rem; border-radius: 4px; border: 1px solid grey; }</style>",
			);
			mywindow.document.write('</head><body >');
			mywindow.document.write(
				document.getElementById('requisition_summary').innerHTML,
			);
			mywindow.document.write('</body></html>');

			return true;
		},
		handleChangeScheduleEmailForm(newVal) {
			console.log({ newVal });
		},
		async handleNewAttachmentAdded({ newFiles }) {
			console.log({ newFiles });
		},
		handleAttachmentDeleteClick({ index }) {
			console.log({ index });
		},
		handleGetUserName({ userId }) {
			const { users } = this;
			const user = users.find(item => item.id === userId);
			if (user) {
				const { name, email } = user;
				if (name) {
					return name;
				}
				return email;
			}
			return '-';
		},
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm:ss' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
		handleSimilarPositionRequisitionsRadioGroupChange(id) {
			if (id === 0) {
				this.handleEmptyRequisitionForm();
			} else {
				this.handleGetRequisition({
					id,
					fillFromSimilarRequisition: true,
				});
			}
		},
		handleEmptyRequisitionForm() {
			this.requisitionFormTemplateId = null;
			this.requisitionFormTemplate = {
				json_schema: [],
				context_schema: {},
				initialized: false,
				job_type: 'new_position',
				number_of_people: 1,
				predefined_position_id: null,
				predefined_position_preset_id: null,
			};
			this.requisitionApprovalFlowTemplateId = null;
			this.requisitionApprovalFlowTemplate = {
				json_schema: [],
				initialized: false,
			};

			const { requisitionFormTemplates, actionType } = this;

			if (requisitionFormTemplates.length > 0 && actionType === 'new') {
				const { id } = requisitionFormTemplates[0];
				this.requisitionFormTemplateId = id;
				this.handleGetRequisitionFormTemplate({ id });
			}
		},
	},
	created() {
		this.$store.dispatch('requisitionFormTemplates/get', {
			updateStore: true,
			refresh: true,
		});
		this.$store.dispatch('offerTemplates/get', {
			updateStore: true,
			refresh: true,
		});
		this.$store.dispatch('requisitionApprovalFlowTemplates/get', {
			updateStore: true,
		});
	},
	mounted() {
		setTimeout(
			function () {
				this.isVisible = true;
			}.bind(this),
			250,
		);
		const { actionType, requisitionId } = this;

		if (actionType === 'edit') {
			this.handleGetRequisition({ id: requisitionId });
		}
	},
	watch: {
		stepper: {
			handler: function (newVal, oldVal) {
				if (newVal && newVal !== oldVal) {
					if (newVal === 1) {
						const { requisitionFormTemplates, actionType } = this;
						const { initialized } = this.requisitionFormTemplate;

						if (
							requisitionFormTemplates.length > 0 &&
							actionType === 'new' &&
							initialized === false
						) {
							const { id } = requisitionFormTemplates[0];
							this.requisitionFormTemplateId = id;
							this.handleGetRequisitionFormTemplate({ id });
						}
					} else if (newVal === 2) {
						this.handlePickingApprovalFlow();
					}
				}
			},
			immediate: true,
			deep: true,
		},
		requisitionFormTemplates: {
			handler: function (newVal) {
				if (newVal && newVal.length > 0) {
					const { actionType } = this;
					const { initialized } = this.requisitionFormTemplate;

					if (actionType === 'new' && initialized === false) {
						const { id } = newVal[0];
						this.requisitionFormTemplateId = id;
						this.handleGetRequisitionFormTemplate({ id });
					}
				}
			},
			deep: true,
			immediate: true,
		},
		requisitionApprovalFlowTemplate: {
			handler: function (newVal) {
				if (
					!this.requisitionApprovalFlowTemplateId &&
					newVal.requisition_approval_flow_template_id
				) {
					this.requisitionApprovalFlowTemplateId =
						newVal.requisition_approval_flow_template_id;
				}

				if (
					!this.requisitionApprovalFlowTemplateId &&
					!newVal.requisition_approval_flow_template_id
				) {
					this.requisitionApprovalFlowTemplateId =
						this.suitableRequisitionApprovalFlowTemplates[0]?.id;
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
