var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',{staticClass:"talentics-fieldset"},[_c('legend',[_vm._v(_vm._s(_vm.$t('label.link-questions')))]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":() =>
									_vm.handleAddPredefinedQuestion({
										platform: 'LinkedIn',
									})}},on),[_c('v-icon',[_vm._v("mdi-linkedin")])],1)]}}])},[_vm._v(" LinkedIn ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":() =>
									_vm.handleAddPredefinedQuestion({
										platform: 'Git',
									})}},on),[_c('v-icon',[_vm._v("mdi-git")])],1)]}}])},[_vm._v(" Git ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":() =>
									_vm.handleAddPredefinedQuestion({
										platform: 'Stackoverflow',
									})}},on),[_c('v-icon',[_vm._v("mdi-stack-overflow")])],1)]}}])},[_vm._v(" Stack Overflow ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":() =>
									_vm.handleAddPredefinedQuestion({
										platform: 'Behance',
									})}},on),[_c('v-icon',[_vm._v("mdi-bootstrap")])],1)]}}])},[_vm._v(" Behance ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":() =>
									_vm.handleAddPredefinedQuestion({
										platform: 'Google Drive',
									})}},on),[_c('v-icon',[_vm._v("mdi-google-drive")])],1)]}}])},[_vm._v(" Google Drive ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":() =>
									_vm.handleAddPredefinedQuestion({
										platform: 'Twitter',
									})}},on),[_c('v-icon',[_vm._v("mdi-twitter")])],1)]}}])},[_vm._v(" Twitter ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":() =>
									_vm.handleAddPredefinedQuestion({
										platform: 'Instagram',
									})}},on),[_c('v-icon',[_vm._v("mdi-instagram")])],1)]}}])},[_vm._v(" Instagram ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":() =>
									_vm.handleAddPredefinedQuestion({
										platform: 'Facebook',
									})}},on),[_c('v-icon',[_vm._v("mdi-facebook")])],1)]}}])},[_vm._v(" Facebook ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":() =>
									_vm.handleAddPredefinedQuestion({
										platform: 'Youtube',
									})}},on),[_c('v-icon',[_vm._v("mdi-youtube")])],1)]}}])},[_vm._v(" Youtube ")]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('button.other').toLocaleUpperCase(_vm.locale))+" ")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-card-text',[_c('ValidationObserver',{ref:"otherPlatformForm",attrs:{"tag":"form","id":"otherPlatformForm"},on:{"submit":_vm.handleAddLinkQuestion}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{ref:"otherPlatform",attrs:{"outlined":"","hide-details":"","dense":"","placeholder":_vm.$t('label.other-platform'),"error-messages":errors},model:{value:(_vm.newLinkQuestion),callback:function ($$v) {_vm.newLinkQuestion=$$v},expression:"newLinkQuestion"}})]}}])})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" "+_vm._s(_vm.$t('button.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleAddLinkQuestion}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" ")])],1)],1)],1)],1)],1),(_vm.valueEditable.length > 0)?_c('v-row',[_c('v-col',[_c('v-divider')],1)],1):_vm._e(),_vm._l((_vm.valueEditable),function(item,index){return _c('v-row',{key:index},[_c('v-col',[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [(item.type === 'dropdown')?_c('v-select',{staticClass:"link-question-input",attrs:{"label":item.label,"items":item.options.map(option => ({
								text: option.label,
								value: option.value,
							})),"outlined":"","hide-details":"","dense":"","error-messages":errors,"name":item.propName,"placeholder":item.label},on:{"change":_vm.handleValueChange},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}):_c('v-text-field',{staticClass:"link-question-input",attrs:{"label":item.label,"flat":"","hide-details":"","outlined":"","error-messages":errors,"name":item.propName,"dense":"","disabled":_vm.isCandidateEdit ? true : false,"placeholder":item.label},on:{"keyup":_vm.handleValueChange},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":() => _vm.handleDeleteLinkQuestion({ index })}},[_c('v-icon',[_vm._v("delete")])],1)],1)],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }