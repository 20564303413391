<template>
	<v-container fluid fill-height>
		<v-layout>
			<v-flex>
				<v-container fluid>
					<v-row>
						<v-col cols="12" sm="12" md="8" lg="6">
							<v-select
								:value="currentUser.lang"
								:items="[
									{ text: 'English', value: 'en' },
									{ text: 'Türkçe', value: 'tr' },
								]"
								item-text="text"
								item-value="value"
								:label="$t('label.language')"
								outlined
								hide-details
								dense
								@change="handleLangChange"
							></v-select>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="12" md="8" lg="6">
							<v-autocomplete
								:value="currentUser.timezone"
								:label="$t('label.timezone')"
								hide-details
								:items="timezoneItems"
								:item-text="item => `${item.text} - ${item.value}`"
								prepend-inner-icon="public"
								outlined
								dense
								@change="handleTimezoneChange"
							></v-autocomplete>
						</v-col>
					</v-row>
					<v-row
						v-if="
							provider === 'google.com' &&
							company.is_calendar_integration_enabled
						"
					>
						<v-col cols="12" sm="12" md="8" lg="6">
							<fieldset class="talentics-fieldset talentics-fieldset-paddings">
								<div style="margin-top: 4px">
									<v-switch
										v-model="syncGoogleCalendar"
										:label="$t('label.sync-google-calendar')"
										color="success"
										@change="
											newVal =>
												handleSyncGoogleCalendarChange({
													newVal,
													confirmed: false,
												})
										"
										inset
										hide-details
										class="v-input--template-switch"
									></v-switch>
								</div>
							</fieldset>
						</v-col>
					</v-row>
					<v-row
						v-if="
							provider === 'microsoft.com' &&
							company.is_calendar_integration_enabled
						"
					>
						<v-col cols="12" sm="12" md="8" lg="6">
							<fieldset class="talentics-fieldset talentics-fieldset-paddings">
								<div style="margin-top: 4px">
									<v-switch
										v-model="syncMicrosoftCalendar"
										:label="$t('label.sync-microsoft-calendar')"
										color="success"
										@change="handleSyncMicrosoftCalendarChange"
										inset
										hide-details
										class="v-input--template-switch"
									></v-switch>
								</div>
							</fieldset>
						</v-col>
					</v-row>
					<v-row
						v-if="
							provider === 'microsoft.com' &&
							company.is_mail_integration_enabled
						"
					>
						<v-col cols="12" sm="12" md="8" lg="6">
							<fieldset class="talentics-fieldset talentics-fieldset-paddings">
								<div style="margin-top: 4px">
									<v-switch
										v-model="syncMicrosoftMail"
										:label="$t('label.sync-microsoft-mail')"
										color="success"
										@change="handleSyncMicrosoftMailChange"
										inset
										hide-details
										class="v-input--template-switch"
									></v-switch>
								</div>
							</fieldset>
						</v-col>
					</v-row>
					<v-row
						v-if="
							provider === 'microsoft.com' &&
							company.is_azure_ad_integration_enabled &&
							currentUser.is_azure_ad_admin
						"
					>
						<v-col cols="12" sm="12" md="8" lg="6">
							<fieldset class="talentics-fieldset talentics-fieldset-paddings">
								<div style="margin-top: 4px">
									<v-switch
										v-model="syncAzureActiveDirectory"
										:label="$t('label.sync-microsoft-azure-ad-iam')"
										color="success"
										@change="handleSyncAzureActiveDirectory"
										inset
										hide-details
										class="v-input--template-switch"
									></v-switch>
								</div>
							</fieldset>
						</v-col>
					</v-row>

					<v-row v-if="provider === 'google.com'">
						<v-col cols="12" sm="12" md="8" lg="6">
							<fieldset class="talentics-fieldset talentics-fieldset-paddings">
								<div style="margin-top: 4px">
									<v-switch
										v-model="syncGoogleMail"
										:label="$t('label.sync-google-gmail')"
										color="success"
										@change="handleSyncGoogleMailChange"
										inset
										hide-details
										class="v-input--template-switch"
									></v-switch>
								</div>
							</fieldset>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" sm="12" md="8" lg="6">
							<fieldset class="talentics-fieldset talentics-fieldset-paddings">
								<div style="margin-top: 4px">
									<v-switch
										v-model="displayAllEventsAsPrivate"
										:label="
											$t('label.display-all-my-events-as-private-to-others')
										"
										color="success"
										@change="handleDisplayAllEventsAsPrivateChange"
										inset
										hide-details
										class="v-input--template-switch"
									></v-switch>
								</div>
							</fieldset>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="12" md="8" lg="6">
							<fieldset class="talentics-fieldset talentics-fieldset-paddings">
								<div style="margin-top: 4px">
									<v-switch
										v-model="appTheme"
										:label="$t('label.dark-mode')"
										color="success"
										@change="handleChangeAppTheme"
										inset
										hide-details
										class="v-input--template-switch"
									></v-switch>
								</div>
							</fieldset>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" sm="12" md="8" lg="6">
							<fieldset class="talentics-fieldset talentics-fieldset-paddings">
								<legend>
									{{ $t('label.email-notification-settings') }}
								</legend>
								<div>
									{{ $t('label.send-me-notification-email-when') }}
								</div>
								<div style="margin-top: 0.25rem; margin-bottom: 0.25rem">
									<v-divider></v-divider>
								</div>
								<div>
									<v-checkbox
										v-model="notifications.email_on_new_app_by_candidate"
										dense
										hide-details
										class="candidate-checkbox"
										:color="isDark ? 'white' : '#121212'"
										:label="$t('label.new-job-application-by-candidate')"
										@change="handleNotificationSettingsChange"
									></v-checkbox>
								</div>
								<div style="margin-top: 0.25rem; margin-bottom: 0.25rem">
									<v-divider></v-divider>
								</div>
								<div>
									<v-checkbox
										v-model="notifications.email_on_new_app_by_agent"
										dense
										hide-details
										class="candidate-checkbox"
										:color="isDark ? 'white' : '#121212'"
										:label="$t('label.new-job-application-added-by-agent')"
										@change="handleNotificationSettingsChange"
									></v-checkbox>
								</div>
								<div style="margin-top: 0.25rem; margin-bottom: 0.25rem">
									<v-divider></v-divider>
								</div>
								<div>
									<v-checkbox
										v-model="notifications.email_on_new_mention"
										dense
										hide-details
										class="candidate-checkbox"
										:color="isDark ? 'white' : '#121212'"
										:label="$t('label.someone-mentions-me')"
										@change="handleNotificationSettingsChange"
									></v-checkbox>
								</div>
								<div style="margin-top: 0.25rem; margin-bottom: 0.25rem">
									<v-divider></v-divider>
								</div>
								<div>
									<v-checkbox
										v-model="notifications.email_on_new_candidate_update"
										dense
										hide-details
										class="candidate-checkbox"
										:color="isDark ? 'white' : '#121212'"
										:label="$t('label.an-update-occurs-about-a-candidate')"
										@change="handleNotificationSettingsChange"
									></v-checkbox>
								</div>
								<div style="margin-top: 0.25rem; margin-bottom: 0.25rem">
									<v-divider></v-divider>
								</div>
								<div>
									<v-checkbox
										v-model="notifications.email_on_new_approval_request"
										dense
										hide-details
										class="candidate-checkbox"
										:color="isDark ? 'white' : '#121212'"
										:label="$t('label.on-new-approval-request')"
										@change="handleNotificationSettingsChange"
									></v-checkbox>
								</div>
								<div
									style="margin-top: 0.25rem; margin-bottom: 0.25rem"
									v-if="updateNotificationsButtonVisible"
								>
									<v-divider></v-divider>
								</div>
								<div
									style="margin-top: 0.5rem"
									v-if="updateNotificationsButtonVisible"
								>
									<v-btn
										color="secondary"
										@click="handleUpdateNotificationsButtonClick"
									>
										<v-icon left>send</v-icon>
										{{ $t('button.update-my-notification-settings') }}
									</v-btn>
								</div>
							</fieldset>
						</v-col>
					</v-row>

					<v-row v-if="chromeExtensionVersion">
						<v-col cols="12" sm="12" md="8" lg="6">
							<fieldset class="talentics-fieldset talentics-fieldset-paddings">
								<legend>
									{{ $t('label.chrome-extension-latest-version') }}
								</legend>
								<v-row>
									<v-col>
										<v-btn
											color="secondary"
											@click="handleDownloadChromeExtension"
										>
											<v-icon left>download</v-icon>
											{{ $t('button.download').toLocaleUpperCase(locale) }}
											{{ chromeExtensionVersion }}
										</v-btn>
									</v-col>
								</v-row>
							</fieldset>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" sm="12" md="8" lg="6">
							<fieldset class="talentics-fieldset talentics-fieldset-paddings">
								<legend>
									{{ $t('label.your-chrome-extension-token') }}
								</legend>
								<v-row>
									<v-col align-self="center">
										{{
											currentUser.auth_uuidv4 ? currentUser.auth_uuidv4 : '-'
										}}
									</v-col>
									<v-col cols="auto" align-self="center">
										<v-btn
											block
											color="secondary"
											@click="handleGenerateChromeExtensionToken"
										>
											<v-icon left>autorenew</v-icon>
											{{ $t('button.generate') }}
										</v-btn>
									</v-col>
								</v-row>
							</fieldset>
						</v-col>
					</v-row>
				</v-container>

				<Confirm
					v-if="isRevokeConfirmVisible"
					:onConfirm="handleConfirmDialogConfirm"
					:onClose="handleConfirmDialogClose"
					primaryActionColor="error"
					><div
						style="padding-top: 20px"
						v-html="$t('text.confirm-revoking-google-mail')"
					></div
				></Confirm>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { mapState } from 'vuex';
import Confirm from '@/components/shared/dialogs/confirm/Confirm';
import api from '@/api';

export default {
	name: 'MyAccount',
	components: {
		Confirm,
	},
	computed: {
		...mapState({
			googleUser: state => state.currentUser.googleUser,
			currentUser: state => state.currentUser.currentUser,
			company: state => state.company.company,
			isDark: state => state.currentUser.isDark,
			provider: state => state.currentUser.provider,
			timezones: state => state.timezones.items,
			currentLang: state => state.currentUser.lang,
		}),
		timezoneItems() {
			const { timezones } = this;
			return timezones.reduce((acc, item) => {
				return [
					...acc,
					{ header: item.group },
					...item.zones.map(zone => ({
						text: zone.name,
						value: zone.value,
						group: item.group,
					})),
				];
			}, []);
		},
		locale() {
			return this.currentLang === 'tr' ? 'tr-TR' : 'en-US';
		}
	},
	data() {
		return {
			syncGoogleCalendar: false,
			syncMicrosoftCalendar: false,
			syncGoogleMail: false,
			syncMicrosoftMail: false,
			syncAzureActiveDirectory: false,
			appTheme: false,
			chromeExtensionVersion: null,
			chromeExtensionUrl: null,
			sendEmailOnCandidateEmails: false,
			updateNotificationsButtonVisible: false,
			displayAllEventsAsPrivate: false,
			notifications: {
				email_on_new_app_by_candidate: false,
				email_on_new_app_by_agent: false,
				email_on_new_mention: false,
				email_on_new_candidate_update: false,
				email_on_new_approval_request: false,
			},
			isRevokeConfirmVisible: false,
		};
	},
	methods: {
		handleSyncGoogleCalendarChange({ newVal, confirmed = false }) {
			const { googleUser } = this;
			const { is_calendar_synced } = this.currentUser;
			if (newVal && googleUser) {
				// https://developers.google.com/identity/sign-in/web/reference#googleauthgrantofflineaccessoptions
				// const options = new window.gapi.auth2.SigninOptionsBuilder({'scope': 'email https://www.googleapis.com/auth/calendar'});
				googleUser
					.grantOfflineAccess({
						scope: 'email https://www.googleapis.com/auth/calendar.events',
					})
					.then(
						response => {
							const { code } = response;
							this.$store.dispatch('currentUser/putCode', {
								code,
								is_calendar_synced: true,
							});
						},
						() => {
							this.syncGoogleCalendar = is_calendar_synced;
						},
					);
			} else {
				if (is_calendar_synced) {
					if (confirmed) {
						googleUser.disconnect().then(async () => {
							await this.$store.dispatch('currentUser/putCode', {
								code: null,
								is_calendar_synced: false,
							});
							this.$store.dispatch('currentUser/signOut');
						});
					} else {
						this.handleRevokeClick();
					}
				}
			}
		},
		async handleSyncGoogleMailChange() {
			if (!this.syncGoogleMail) {
				api.authUser
					.revokeGoogleMail()
					.then(() => {
						console.log('google mail consent revoked');
					})
					.catch(console.error);
			} else {
				api.authUser
					.syncGoogleMail()
					.then(response => {
						const redirect_url = response.data.redirect_url;
						window.location.replace(redirect_url);
					})
					.catch(console.error);
			}
		},
		async handleSyncMicrosoftMailChange() {
			if (!this.syncMicrosoftMail) {
				await this.$store.dispatch('currentUser/revokeMicrosoftMailConsent', {
					userId: this.currentUser.id,
				});
			} else {
				window.location.replace(
					`${process.env.VUE_APP_HOST_API_KEY}v1/hire-app/ms-auth?service=mail&id_token=${this.currentUser.id_token}`,
				);
			}
		},
		async handleSyncMicrosoftCalendarChange() {
			if (!this.syncMicrosoftCalendar) {
				await this.$store.dispatch(
					'currentUser/revokeMicrosoftCalendarConsent',
					{ userId: this.currentUser.id },
				);
			} else {
				window.location.replace(
					`${process.env.VUE_APP_HOST_API_KEY}v1/hire-app/ms-auth?service=calendar&id_token=${this.currentUser.id_token}`,
				);
			}
		},
		async handleSyncAzureActiveDirectory() {
			if (!this.syncAzureActiveDirectory) {
				await this.$store.dispatch('company/put', {
					is_azure_ad_synced: false,
				});
			} else {
				window.location.replace(
					`${process.env.VUE_APP_HOST_API_KEY}v1/hire-app/ms-auth?service=azure_ad&id_token=${this.currentUser.id_token}`,
				);
			}
		},
		handleChangeAppTheme(val) {
			api.authUser.put({ is_dark: val }).then(() => {
				this.$store.commit('currentUser/setIsDark', { isDark: val });
			});
		},
		handleLangChange(lang) {
			api.authUser.put({ lang }).then(() => {
				window.location.reload();
			});
		},
		handleTimezoneChange(timezone) {
			const { id } = this.currentUser;
			this.$store
				.dispatch('users/put', {
					id,
					timezone,
				})
				.then(() => {
					this.$store.commit('currentUser/setTimezone', { timezone });
				});
		},
		handleGetAzureAdSyncStatus() {
			api.company.get({}).then(response => {
				const { items } = response.data;
				const { is_azure_ad_synced } = items[0];
				this.syncAzureActiveDirectory = is_azure_ad_synced;
			});
		},
		handleGetChromeExtensionVersions() {
			api.chromeExtensionVersions.get({}).then(response => {
				const { items } = response.data;
				if (items.length > 0) {
					const { version, url } = items[0];
					this.chromeExtensionVersion = version;
					this.chromeExtensionUrl = url;
				}
			});
		},
		handleDownloadChromeExtension() {
			const { chromeExtensionUrl } = this;
			window.open(chromeExtensionUrl);
		},
		handleGenerateChromeExtensionToken() {
			api.chromeExtensionToken.put().then(response => {
				const { items } = response.data;
				if (items.length > 0) {
					const { auth_uuidv4 } = items[0];
					this.$store.commit('currentUser/setUuidv4', {
						auth_uuidv4,
					});
				}
			});
		},
		handleGetNotificationSettings() {
			api.notificationSettings.get().then(response => {
				const {
					email_on_new_app_by_candidate,
					email_on_new_app_by_agent,
					email_on_new_mention,
					email_on_new_candidate_update,
					email_on_new_approval_request,
				} = response.data;
				this.notifications = {
					email_on_new_app_by_candidate,
					email_on_new_app_by_agent,
					email_on_new_mention,
					email_on_new_candidate_update,
					email_on_new_approval_request,
				};
			});
		},
		handleNotificationSettingsChange() {
			this.updateNotificationsButtonVisible = true;
		},
		handleUpdateNotificationsButtonClick() {
			api.notificationSettings.put(this.notifications).then(() => {
				this.updateNotificationsButtonVisible = false;
			});
		},
		handleDisplayAllEventsAsPrivateChange(display_all_events_as_private) {
			api.authUser.put({ display_all_events_as_private });
		},
		handleConfirmDialogConfirm() {
			this.handleSyncGoogleCalendarChange({
				newVal: false,
				confirmed: true,
			});
		},
		handleConfirmDialogClose() {
			this.isRevokeConfirmVisible = false;
			this.syncGoogleCalendar = true;
		},
		handleRevokeClick() {
			this.isRevokeConfirmVisible = true;
		},
	},
	created() {
		this.$store.dispatch('timezones/get', { updateStore: true });
	},
	mounted() {
		this.handleGetChromeExtensionVersions();
		this.handleGetNotificationSettings();

		const { provider, currentUser } = this;

		if (provider === 'microsoft.com' && currentUser.is_azure_ad_admin) {
			this.handleGetAzureAdSyncStatus();
		}
	},
	watch: {
		isDark: {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal) {
					this.appTheme = newVal;
				}
			},
			immediate: true,
		},
		currentUser: {
			handler: function (newVal) {
				if (newVal) {
					const {
						is_calendar_synced,
						is_mail_synced,
						display_all_events_as_private,
					} = this.currentUser;
					this.syncGoogleCalendar = is_calendar_synced;
					this.syncMicrosoftCalendar = is_calendar_synced;
					this.displayAllEventsAsPrivate = display_all_events_as_private;
					this.syncMicrosoftMail = is_mail_synced;
					this.syncGoogleMail = is_mail_synced;
				}
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>
