const getCandidateLastName = ({ name }) => {
    if (name) {
        const trimmedName = name.trim().replace(/ +(?= )/g, '');
        const nameParts = trimmedName.split(' ');
        if (nameParts.length > 1) {
            const lastNameIndex = nameParts.length - 1;
            const lastName = nameParts[lastNameIndex];
            return lastName;
        }
    }
    return '';
};

const getCandidateFirstName = ({ name }) => {
    if (name) {
        const nameArr = name
            .trim()
            .replace(/ +(?= )/g, '')
            .split(' ');
        return nameArr[0];
    }
    return '';
};

const toLocaleCapitalize = (str, locale) => {
    return str.replace(/\w\S*/g, function (txt) {
        const firstChar = txt.charAt(0);
        const restOfString = txt.substr(1);

        if (locale === 'tr') {
            const turkishMap = {
                i: 'İ',
                ş: 'Ş', 
                ç: 'Ç', 
                ğ: 'Ğ', 
                ü: 'Ü', 
                ö: 'Ö', 
            };

            const capitalizedFirstChar =
                turkishMap[firstChar] || firstChar.toLocaleUpperCase(locale);

            return (
                capitalizedFirstChar + restOfString.toLocaleLowerCase(locale)
            );
        }

        return firstChar.toLocaleUpperCase(locale) + restOfString.toLocaleLowerCase(locale);
    });
};

export {
    getCandidateLastName,
    getCandidateFirstName,
    toLocaleCapitalize
}